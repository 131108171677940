import { Space, Popover, Typography } from "antd";
import { LogoutOutlined } from "@ant-design/icons";
import { FlabsLogo } from "../asssets";
import classes from "../styles/Medicalreport.module.css";
import { Profile, DownArrow } from "../asssets";
import { connect } from "react-redux";
import { logout } from "../User/UserActions";
// const { Header } = Layout;
const { Text } = Typography;

const TopBar = (props) => {
  console.log(props?.userDetails);
  const Title = () => {
    return (
      <div style={{ display: "flex", justifyContent: "flex-start" }}>
        <div>
          <Profile width={"60px"} height={"60px"} />
        </div>
        <div style={{ marginTop: "10px", marginLeft: "10px" }}>
          <div>
            <Text>Hello!</Text>
          </div>
          <div>
            <Text style={{ fontSize: "12px" }}>
              {props?.userDetails?.user?.contact}
            </Text>
          </div>
        </div>
      </div>
    );
  };
  const AdminContent = () => {
    return (
      <div>
        <div
          style={{
            width: "100%",
            cursor: "pointer",
          }}
          onClick={() => props.logouts()}
        >
          <LogoutOutlined /> <span style={{ paddingLeft: "8px" }}>Logout</span>
        </div>
      </div>
    );
  };
  return (
    <div
      style={{
        position: "sticky",
        top: "0",
        zIndex: "10",
        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.08)",
      }}
    >
      <div className={classes["navbar-background"]}>
        <div className={classes["main-contianer"]}>
          <div className={classes["header-left-side"]}>
            <div className={classes["flabs-logo"]}>
              <FlabsLogo className={classes["flabs-logo-size"]} />
            </div>
          </div>
          <div>
            <div className={classes["header-avatar-select"]}>
              <Popover
                placement="bottomRight"
                title={Title}
                content={AdminContent}
                trigger="click"
              >
                <Space
                  style={{
                    cursor: "pointer",
                  }}
                >
                  <div className={classes["header-avatar-div"]}>
                    <Profile width={"34px"} height={"34px"} />
                  </div>
                  <DownArrow width={"10px"} height={"10px"} />
                </Space>
              </Popover>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => {
  let userDetails = state.user?.user;

  return {
    userDetails,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    logouts: (params) => dispatch(logout(params)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TopBar);
