import { takeLatest, fork } from "redux-saga/effects";
import ACTIONS from "./UserActionsType";
import { Store } from "../Config/Store";
import axios from "axios";
import { message } from "antd";

function* getUser(action) {
  const { dispatch } = Store;
  try {
    axios
      .get(`/auth/current_user_lab`)
      .then((res) => {
        if (res.data.status === 401 || res.data.status === 400) {
          localStorage.removeItem("token");
          window.location.pathname = "/";
          return;
        }
        dispatch({
          type: ACTIONS.FETCH_USER_SUCCESS,
          payload: res.data,
        });
      })
      .catch((err) => {
        console.log(err?.response?.status);
        if (err?.response?.status === 401 || err?.response?.status === 400) {
          localStorage.removeItem("token");
          window.location.pathname = "/";
          return;
        }
        if (err?.response?.status === 403) {
          message.error(
            "Your Account is not Active. Please contact your Lab Admin!"
          );
        }
        // if (localStorage.getItem('token')) {
        //   localStorage.removeItem('token');
        //   window.location.pathname = '/';
        // }
        // dispatch({
        //   type: ACTIONS.FETCH_USER_FAIL,
        // });
      });
  } catch (error) {
    dispatch({
      type: ACTIONS.FETCH_USER_FAIL,
    });
    // console.log("get shop error: ", error);
  }
}

function* watchUsers() {
  yield takeLatest(ACTIONS.FETCH_USER_REQUEST, getUser);
}

// ACTION WATCHER
export default function* userSaga() {
  yield fork(watchUsers);
}
