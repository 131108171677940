import React, { useEffect, useState } from "react";
import styles from "../styles/reportStatus.module.css";
import { Timeline, Spin } from "antd";
import { PhoneOutlined } from "@ant-design/icons";
import { useParams } from "react-router-dom";
import axios from "axios";
import {
  StepsTickComplete,
  TestProcessCurrent,
  ReportSharePending,
  ResultCheckPending,
  TestResultPending,
  FlabsLogo,
  ReportShareComplete,
  ReportShareCurrent,
  ResultCheckComplete,
  TestResultComplete,
  TestProcessComplete,
} from "../asssets";

const ReportStatus = () => {
  const [billStatusData, setBillStatusData] = useState({});
  const [statusDisplay, setStatusDisplay] = useState(null);
  const { billId } = useParams();

  const STATUS_ONGOING = "Current";
  const STATUS_COMPLETED = "Completed";
  const STATUS_PENDING = "Pending";

  useEffect(() => {
    getStatusUpdate();
  }, []);

  const convertDate = (inputDate) => {
    const formattedDate = inputDate.toLocaleString("en-US", {
      day: "numeric",
      month: "short",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    });
    return formattedDate;
  };

  const getStatusUpdate = async () => {
    try {
      const res = await axios.get("/billing/trackBillStatus", {
        params: {
          billNumber: billId,
        },
      });
      setBillStatusData(res?.data);

      handleStatus(res?.data, res?.data?.status);
    } catch (error) {
      console.log(error);
    }
  };

  const handleStatus = (data, billStatus) => {
    try {
      const display = {
        booking: {
          icon: STATUS_COMPLETED,
          date: convertDate(new Date(data.createdAt)),
        },
        test: {
          icon: "",
          date: "",
        },
        reportShare: {
          icon: "",
          date: "",
        },
      };

      // If status is ongoing update test and reportShare icon and date
      if (billStatus === "ongoing") {
        display.test.icon = STATUS_ONGOING;
        display.test.date = STATUS_PENDING;

        display.reportShare.icon = STATUS_PENDING;
        display.reportShare.date = STATUS_PENDING;
      }

      // if status is completed
      else if (billStatus === "completed") {
        const testStatus = data.statusUpdate.find(
          (item) => item.status === "completed"
        );
        display.test.icon = STATUS_COMPLETED;
        display.test.date = convertDate(new Date(testStatus?.date));

        display.reportShare.icon = STATUS_ONGOING;
        display.reportShare.date = STATUS_PENDING;
      }
      // if status is delivered
      else if (billStatus === "delivered") {
        const testStatus = data.statusUpdate.find(
          (item) => item.status === "completed"
        );
        display.test.icon = STATUS_COMPLETED;
        display.test.date = convertDate(new Date(testStatus?.date));

        const reportShareStatus = data.statusUpdate.find(
          (item) => item.status === "delivered"
        );
        display.reportShare.icon = STATUS_COMPLETED;
        display.reportShare.date = convertDate(
          new Date(reportShareStatus?.date)
        );
      }

      setStatusDisplay(display);
    } catch (error) {
      console.log(error);
    }
  };

  console.log(statusDisplay);

  const timeLineItems = [
    {
      dot: <StepsTickComplete />,
      children: (
        <div className={styles["step-item"]}>
          <h3>Booking</h3>
          <p>{statusDisplay?.booking.date}</p>
        </div>
      ),
    },
    {
      dot:
        statusDisplay?.test.icon === STATUS_ONGOING ? (
          <TestProcessCurrent />
        ) : (
          <TestProcessComplete />
        ),
      children: (
        <div
          className={styles["step-item"]}
          style={{
            color: `${
              statusDisplay?.test.icon === STATUS_PENDING
                ? "rgba(0, 0, 0, 0.45)"
                : ""
            }`,
          }}
        >
          <h3>
            {statusDisplay?.test.icon === STATUS_ONGOING
              ? "Test Process"
              : "Test Process Done"}
          </h3>
          <p>{statusDisplay?.test.date}</p>
        </div>
      ),
    },
    {
      dot:
        statusDisplay?.test.icon === STATUS_ONGOING ? (
          <TestResultPending />
        ) : (
          <TestResultComplete />
        ),
      children: (
        <div
          className={styles["step-item"]}
          style={{
            color: `${
              statusDisplay?.test.icon === STATUS_ONGOING
                ? "rgba(0, 0, 0, 0.45)"
                : ""
            }`,
          }}
        >
          <h3>
            {statusDisplay?.test.icon === STATUS_ONGOING
              ? "Test Result"
              : "Test Result Added"}
          </h3>
          <p>{statusDisplay?.test.date}</p>
        </div>
      ),
    },
    {
      dot:
        statusDisplay?.test.icon === STATUS_ONGOING ? (
          <ResultCheckPending />
        ) : (
          <ResultCheckComplete />
        ),
      children: (
        <div
          className={styles["step-item"]}
          style={{
            color: `${
              statusDisplay?.test.icon === STATUS_ONGOING
                ? "rgba(0, 0, 0, 0.45)"
                : ""
            }`,
          }}
        >
          <h3>
            {statusDisplay?.test.icon === STATUS_ONGOING
              ? "Result Check"
              : "Result Checked"}
          </h3>
          <p>{statusDisplay?.test.date}</p>
        </div>
      ),
    },
    {
      dot:
        statusDisplay?.reportShare.icon === STATUS_PENDING ? (
          <ReportSharePending />
        ) : statusDisplay?.reportShare.icon === STATUS_ONGOING ? (
          <ReportShareCurrent />
        ) : (
          <ReportShareComplete />
        ),
      children: (
        <div
          className={styles["step-item"]}
          style={{
            color: `${
              statusDisplay?.reportShare.icon === STATUS_PENDING
                ? "rgba(0, 0, 0, 0.45)"
                : ""
            }`,
          }}
        >
          <h3>
            {statusDisplay?.reportShare.icon === STATUS_COMPLETED
              ? "Report Shared"
              : "Report Share"}
          </h3>
          <p>{statusDisplay?.reportShare.date}</p>
        </div>
      ),
    },
  ];
  return (
    <div className={styles["report-status-container"]}>
      {statusDisplay ? (
        <>
          <div className={styles["rs-header"]}>
            <h1>{billStatusData.lab.name}</h1>
            <p>{billStatusData.lab.address}</p>
          </div>

          <div className={styles["patient-info"]}>
            <h2>
              {billStatusData?.patient?.first_name + " "}
              {billStatusData?.patient?.last_name === undefined
                ? ""
                : billStatusData?.patient?.last_name}
            </h2>
            <p>
              {billStatusData?.patient?.gender.charAt(0).toUpperCase() +
                billStatusData?.patient?.gender.slice(1) +
                " | " +
                billStatusData?.patient?.age +
                " yrs"}
            </p>
          </div>

          <h2 className={styles["rs-title"]}>Report Status</h2>
          <hr
            style={{
              width: "100%",
              border: "1px solid rgba(0, 0, 0, 0.08)",
            }}
          />
          <div className={styles["report-status-steps"]}>
            <Timeline items={timeLineItems} />
          </div>

          <a
            href={`tel:${billStatusData.lab.contact}`}
            className={styles["call-btn"]}
          >
            <PhoneOutlined
              style={{
                transform: "rotate(90deg)",
                color: "#fff",
                fontSize: "20px",
              }}
            />
            Call
          </a>
          {/* <FlabsLogo width={82} height={20} className={styles["rs-footer"]} /> */}
        </>
      ) : (
        <Spin
          size="large"
          style={{
            position: "absolute",
            left: "50%",
            top: "50%",
          }}
        />
      )}
    </div>
  );
};

export default ReportStatus;
