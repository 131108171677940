import React from "react";
import { Button, Typography, Divider, message } from "antd";
import { DownloadOutlined, WhatsAppOutlined } from "@ant-design/icons";
import { Empty } from "../asssets";
import moment from "moment";
import axios from "axios";
const { Text } = Typography;

const MedicalReportMobileView = ({ data }) => {
  const sendReport = (id) => {
    axios
      .get(`/patient/sendReport?billNumber=${id}`)
      .then((res) => {
        message.success("Report sent successfully");
      })
      .catch((err) => {
        message.error("Something went wrong");
      });
  };
  return (
    <>
      {data.length === 0 ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "10%",
            marginLeft: "-5%",
          }}
        >
          <Empty />
        </div>
      ) : (
        <>
          {data.map((item) => (
            <div>
              <div
                style={{
                  width: "100%",
                  border: "1px solid rgba(0, 0, 0, 0.15)",
                  borderRadius: "4px",
                  marginBottom: "12px",
                }}
              >
                <div style={{ padding: "12px" }}>
                  <div>
                    <Text strong style={{ fontSize: "16px" }}>
                      {item.lab}
                    </Text>
                  </div>
                  <div style={{ marginTop: "12px" }}>
                    <Text style={{ color: "rgba(0, 0, 0, 0.45)" }}>
                      Test Name : {item?.tests?.map((item) => item).join(", ")}
                    </Text>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginTop: "12px",
                    }}
                  >
                    <div>
                      <Text style={{ color: "rgba(0, 0, 0, 0.45)" }}>
                        {item.patient}
                      </Text>
                    </div>
                    <div>
                      <Text style={{ color: "rgba(0, 0, 0, 0.45)" }}>
                        {item?.reportDate
                          ? moment(item?.reportDate).format("DD-MM-YYYY")
                          : "-"}
                        {/* {item.date} */}
                      </Text>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginTop: "12px",
                    }}
                  >
                    <Button
                      style={{
                        color: "#25D366",
                        borderColor: "#25D366",
                        width: "47%",
                      }}
                      onClick={() => sendReport(item?._id)}
                    >
                      Get On <WhatsAppOutlined />
                    </Button>
                    <a
                      href={item?.reportUrl}
                      style={{
                        width: "47%",
                      }}
                      target="_blank"
                    >
                      <Button type="primary" style={{ width: "100%" }}>
                        <DownloadOutlined /> Download
                      </Button>
                    </a>
                  </div>
                </div>
              </div>
              <div style={{ width: "100%" }}>
                <Divider
                  style={{
                    marginTop: "12px",
                    marginBottom: "12px",
                    backgroundColor: "#00000014",
                  }}
                />
              </div>
            </div>
          ))}
        </>
      )}
    </>
  );
};

export default MedicalReportMobileView;
