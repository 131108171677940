import { Input, Divider, DatePicker, ConfigProvider } from "antd";
import { useEffect, useState } from "react";
import classes from "../styles/Medicalreport.module.css";
import TopBar from "../components/TopBar";
import { SearchOutlined } from "@ant-design/icons";
import MedicalReportTable from "../components/MedicalReportTable";
import MedicalReportMobileView from "../components/MedicalReportMobileView";
import axios from "axios";
const { Search } = Input;
const { RangePicker } = DatePicker;

const MedicalReport = () => {
  const [data, setData] = useState([]);
  // let data = [
  //   {
  //     key: "1",
  //     testName: "Lorem gypsum 3",
  //     labName: "Lorem gypsum 3",
  //     name: "John Brown",
  //     date: "18/02/2023",
  //     age: 32,
  //     address: "New York No. 1 Lake Park",
  //     tags: ["nice", "developer"],
  //   },
  //   {
  //     key: "2",
  //     testName: "Lorem gypsum 4",
  //     labName: "Lorem gypsum 4",
  //     name: "Jim Green",
  //     date: "18/02/2023",
  //     age: 42,
  //     address: "London No. 1 Lake Park",
  //     tags: ["loser"],
  //   },
  //   {
  //     key: "3",
  //     testName: "Lorem gypsum 5",
  //     labName: "Lorem gypsum 5",
  //     name: "Joe Black",
  //     date: "18/02/2023",
  //     age: 32,
  //     address: "Sydney No. 1 Lake Park",
  //     tags: ["cool", "teacher"],
  //   },
  //   {
  //     key: "3",
  //     testName: "Lorem gypsum 6",
  //     labName: "Lorem gypsum 6",
  //     name: "Joe Black",
  //     date: "18/02/2023",
  //     age: 32,
  //     address: "Sydney No. 1 Lake Park",
  //     tags: ["cool", "teacher"],
  //   },
  //   {
  //     key: "3",
  //     testName: "Lorem gypsum 7",
  //     labName: "Lorem gypsum 7",
  //     name: "Joe Black",
  //     date: "18/02/2023",
  //     age: 32,
  //     address: "Sydney No. 1 Lake Park",
  //     tags: ["cool", "teacher"],
  //   },
  //   {
  //     key: "3",
  //     testName: "Lorem gypsum 8",
  //     labName: "Lorem gypsum 8",
  //     name: "Ankit Mittal",
  //     date: "18/02/2023",
  //     age: 32,
  //     address: "Sydney No. 1 Lake Park",
  //     tags: ["cool", "teacher"],
  //   },
  //   {
  //     key: "3",
  //     testName: "Lorem gypsum 9",
  //     labName: "Lorem gypsum 9",
  //     name: "Joe Black",
  //     date: "18/02/2023",
  //     age: 32,
  //     address: "Sydney No. 1 Lake Park",
  //     tags: ["cool", "teacher"],
  //   },
  //   {
  //     key: "3",
  //     testName: "Lorem gypsum 10",
  //     labName: "Lorem gypsum 10",
  //     name: "Joe Black",
  //     date: "18/02/2023",
  //     age: 32,
  //     address: "Sydney No. 1 Lake Park",
  //     tags: ["cool", "teacher"],
  //   },
  //   {
  //     key: "3",
  //     testName: "Lorem gypsum",
  //     labName: "Lorem gypsum",
  //     name: "Joe Black",
  //     date: "18/02/2023",
  //     age: 32,
  //     address: "Sydney No. 1 Lake Park",
  //     tags: ["cool", "teacher"],
  //   },
  // ];
  useEffect(() => {
    fetchReports();
  }, []);
  const fetchReports = () => {
    axios.get("/patient/reports").then((res) => {
      console.log(res.data);
      setData(res.data);
    });
  };
  const [searchField, setSearchField] = useState("");
  const handleChange = (e) => {
    console.log(e.target.value);
    setSearchField(e.target.value);
  };

  const filteredData = data.filter((data) => {
    if (searchField === "") {
      return data;
    }
    if (data?.patient?.toLowerCase().includes(searchField.toLowerCase())) {
      return data;
    }
    if (
      data?.tests?.find((test) => {
        return test.toLowerCase().includes(searchField.toLowerCase());
      })
    ) {
      return data;
    }
    // return data;
  });
  return (
    <ConfigProvider
      theme={{
        token: {
          borderRadius: "0",
        },
      }}
    >
      <div className={classes["medical-report-container"]}>
        <TopBar />
        <div className={classes["medical-report-content"]}>
          <div className={classes["dekstop-view"]}>
            <div className={classes["heading-bar"]}>
              <div className={classes["heading-bar-container"]}>
                <div className={classes["heading-bar-left"]}>
                  <h2 className={classes["heading-bar-heading"]}>
                    Medical Reports
                  </h2>
                </div>
                <div className={classes["heading-bar-right"]}>
                  <RangePicker
                    style={{ width: "30%", height: "fit-content" }}
                  />
                  <Search
                    placeholder="Search by Test or Patient Name"
                    style={{ width: "67%" }}
                    allowClear
                    onChange={handleChange}
                  />
                </div>
              </div>
            </div>
            <div style={{ width: "100%" }}>
              <Divider style={{ marginTop: "12px" }} />
            </div>
            <div>
              <MedicalReportTable data={filteredData} />
            </div>
          </div>
          <div className={classes["mobile-view"]}></div>
          <div className={classes["mobile-view"]}>
            <div className={classes["heading-bar"]}>
              <div className={classes["heading-bar-container"]}>
                <div className={classes["heading-bar-left"]}>
                  <h2 className={classes["heading-bar-heading"]}>
                    Medical Reports
                  </h2>
                </div>
                <div className={classes["heading-bar-right"]}>
                  <RangePicker
                    style={{
                      width: "100%",
                      // height: "fit-content",
                      size: "large",
                      marginTop: "10px",
                      height: "34px",
                    }}
                  />
                  <Input
                    placeholder="Search by Test or Patient Name"
                    prefix={<SearchOutlined />}
                    size="large"
                    style={{ width: "100%", height: "34px" }}
                    onChange={handleChange}
                  />
                </div>
              </div>
            </div>
            <div style={{ width: "100%" }}>
              <Divider style={{ marginTop: "12px", marginBottom: "12px" }} />
            </div>
            <MedicalReportMobileView data={filteredData} />
          </div>
        </div>
      </div>
    </ConfigProvider>
  );
};
export default MedicalReport;
