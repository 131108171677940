import { useState, useEffect } from "react";
import { Input, Button, Typography, Divider, message } from "antd";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useParams } from "react-router-dom";
import { FlabsLogo } from "../asssets";
import classes from "../styles/login.module.css";
import Footer from "../components/Footer";
import axios from "axios";
import { connect } from "react-redux";
import { login } from "../User/UserActions";
const { Text } = Typography;

const OtpPage = (props) => {
  let navigate = useNavigate();
  const { token, code } = useParams();
  const [otp, setOtp] = useState("");
  const [resend, setResend] = useState(true);
  const [minutes, setMinutes] = useState(1);
  const [seconds, setSeconds] = useState(30);
  const changeHandler = (e) => {
    setOtp(e.target.value);
  };
  const submitHandler = async () => {
    const obj = {
      otp: otp,
      token: token,
    };
    axios.post("/patient/verifylogin", obj).then((res) => {
      // console.log(res);
      props.fetchUser(res.data);
      navigate("/home");
      window.location.reload();
      // props.history.push('/home');
    });
  };
  const resend_otp = () => {
    axios
      .post("/auth/resend_otp", {
        token: token,
        code: code,
      })
      .then((res) => {
        console.log(res);
        // setSearchParams({
        //   token: res.data.token,
        // });
        message.success("OTP sent successfully");
      })
      .catch((err) => {
        console.log(err);
        message.error("Something went wrong. Please try again later.");
      });
    setSeconds(30);
    setMinutes(1);
    setResend(true);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }

      if (seconds === 0) {
        if (minutes === 0) {
          setResend(false);
          clearInterval(interval);
        } else {
          setSeconds(59);
          setMinutes(minutes - 1);
        }
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [minutes, seconds]);
  const enterLogin = (event) => {
    if (event.keyCode === 13) {
      submitHandler();
    }
  };
  return (
    <>
      <div className={classes["main-container"]}>
        {/* <div className={classes["background-img1"]}></div>
        <div className={classes["background-img2"]}></div> */}
        <div className={classes["flabs-logo"]}>
          <FlabsLogo className={classes["flabs-logo-size"]} />
        </div>
        <div className={classes["login-background"]}>
          <div className={classes["login-container"]}>
            <div className={classes["inner-content"]}>
              <Text style={{ fontSize: "16px", fontWeight: "450" }}>
                Patient Log In
              </Text>
              <Divider style={{ marginTop: "12px" }} />
              <div className={classes["input-bar-div"]}>
                <Input
                  type="number"
                  size="large"
                  placeholder="Enter OTP"
                  value={otp}
                  onChange={changeHandler}
                  required
                  onKeyDown={(e) => enterLogin(e)}
                />
              </div>
              <div className={classes["resend-otp"]}>
                <div>
                  <p style={{ marginTop: "4px" }}>
                    Resend in {minutes < 10 ? `0${minutes}` : minutes}:
                    {seconds < 10 ? `0${seconds}` : seconds}
                  </p>
                </div>
                <Button
                  onClick={() => resend_otp()}
                  type="link"
                  disabled={resend}
                >
                  Resend OTP
                </Button>
              </div>
              <div>
                <Button
                  type="primary"
                  size="large"
                  style={{ paddingLeft: "10px", paddingRight: "10px" }}
                  onClick={submitHandler}
                >
                  Log In
                </Button>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchUser: (id) => dispatch(login(id)),
  };
};

export default connect(null, mapDispatchToProps)(OtpPage);
