import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Login from "./pages/Login";
import OtpPage from "./pages/OtpPage";
import MedicalReport from "./pages/MedicalReport";
import "./App.less";
import Loader from "./components/Loader/Loader";
import { fetchUser } from "./User/UserActions";
import { connect } from "react-redux";
import { useEffect } from "react";
import PrivateRoute from "./helper/privateRoute";
import ReportStatus from "./pages/ReportStatus";

function App(props) {
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      props?.userDetails && props?.fetchUsers();
    }
  }, []);
  if (props?.loading) {
    return <Loader />;
  }
  return (
    <Router>
      <Routes>
        <Route path="/" index element={<Login />} />
        <Route path="/otp/:token/:code" index element={<OtpPage />} />
        <Route path="/report-status/:billId" element={<ReportStatus />} />
        <Route element={<PrivateRoute />}>
          <Route path="/home" index element={<MedicalReport />} />
        </Route>
      </Routes>
    </Router>
  );
}

const mapStateToProps = (state) => {
  let userDetails = state.user.user;
  let loading = state.user.loading;

  return {
    userDetails,
    loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchUsers: (params) => dispatch(fetchUser(params)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
