import React from "react";

const Footer = () => {
  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: "-40px",
        }}
      >
        <p style={{ color: "#A9A9A9" }}>copyright@2024,Flabs</p>
      </div>
    </div>
  );
};

export default Footer;
